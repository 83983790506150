@use '../abstracts/mixins' as *;

.hamburger {
  --width: 6.25rem;
  --clr: var(--clr-primary-5, blue);
  --gap: 0.625rem;
  --thickness: 0.25rem;

  gap: var(--gap);
  width: var(--width);
  display: flex;
  flex-direction: column;
  align-items: stretch;
  background: transparent;
  padding: 1.5rem;
  border: none;
  cursor: pointer;

  span {
    background: var(--clr);
    height: var(--thickness);
    width: 70%;
    border-radius: 20px;
  }

  &::before,
  &::after {
    content: '';
    background: var(--clr);
    height: var(--thickness);
    width: 100%;
    position: relative;
    transition: all ease 0.2s;
    border-radius: 20px;
  }

  &::before {
    left: 0;
  }

  &::after {
    top: 0;
    left: 0;
  }
}

.nav--open .hamburger {
  &::before {
    top: 0.9375rem;
    transform: rotate(45deg);
  }

  &::after {
    transform: rotate(-45deg);
    top: -0.75rem;
  }

  span {
    opacity: 0;
  }
  @include mq(medium, max) {
    &::before,
    &::after {
      content: '';
      background: var(--clr-neutral-1);
      height: var(--thickness);
      width: 100%;
      position: relative;
      transition: all ease 0.2s;
      border-radius: 20px;
    }
    position: relative;
    z-index: 99999;
  }
}
