@use '../abstracts/mixins' as *;
@use '../utilities/container';

.streamfield {
  @extend .container !optional;

  position: relative;
  z-index: 0;

  & & {
    padding: 0;
    width: min(100% - 2rem, 800px);

    @include mq(big) {
      width: 100%;
    }
    @media screen and (max-width: 40rem) {
      margin-inline: 0;
      width: 100%;
    }
  }

  & &:first-child {
    margin-block-start: 0;
  }

  & &:last-child {
    margin-block-end: 0;
  }
}

// @include mq(medium) {
//   .streamfield {
//     .container {
//       width: min(100%, 1200px);
//     }
//   }
// }
