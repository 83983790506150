@use '../abstracts/mixins' as *;
.featuretiles {
  .featuretile {
    @include mq(mini, max) {
      margin-block-end: 2rem;
    }

    &__text--small,
    &__text {
      --font-weight: 600;
    }

    &__text {
      font-size: var(--fs-200);
      text-align: center;
      margin-top: -8px;
      @include mq(small) {
        font-size: var(--fs-500);
      }
    }

    &__text--small {
      max-width: 80%;
      margin-inline: auto;
      margin-block-start: 0.5rem;
      hyphens: auto;
      font-size: 1rem;

      @include mq(small) {
        font-size: 1.4rem;
        margin-block-start: 1rem;
      }
    }

    &__bgwrapper {
      background-color: var(--clr-primary-5);
      color: var(--clr-neutral-1);
      height: clamp(6.75rem, -4.3438rem + 35.5vw, 15.625rem);

      .card__iconwrapper {
        position: relative;
        height: 70%;
        display: flex;
        align-items: center;

        img {
          position: relative !important;
          margin-inline: auto;
          max-height: 40px;
          max-width: 60px;

          @include mq(small) {
            max-width: 120px;
            max-height: inherit;
          }
        }
      }
    }
  }

  &__wrapper {
    text-align: center;

    @include mq(mini) {
      display: grid;
      grid-template-columns: repeat(3, clamp(6.75rem, -4.3438rem + 35.5vw, 15.625rem));
      grid-column-gap: 1em;
      justify-content: center;
    }
  }
}
