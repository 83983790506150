@use '../abstracts/mixins' as *;

.header {
  @include mq(small) {
    --header-height: 8.25rem;
  }

  --header-height: 5.125rem;
  position: sticky;
  top: 0;
  background-color: var(--clr-neutral-1);
  min-height: var(--header-height);
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: min(100%, 1400px);
  margin: auto;

  .homelink {
    color: var(--clr-primary-5);
    margin-inline-start: 1rem;
  }

  &__logo {
    height: 4.2rem;
    position: relative;
    top: 2px;
    left: -6px;
  }

  &__link {
    display: inline-block;
    padding: 1.5rem;
  }

  @include mq(medium, max) {
    &.nav--open {
      background-color: var(--clr-primary-5);
      color: var(--clr-neutral-1);
      .homelink {
        z-index: 99999;
        filter: brightness(0) invert(1);
      }
    }
  }
}
