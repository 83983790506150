@use '../abstracts/mixins' as *;

#main {
  .pageheader {
    background: linear-gradient(0deg, var(--clr-primary-5) 55%, var(--clr-secondary-5) 45%);
    color: var(--clr-neutral-1);
    width: min(100%, 1400px);
    margin-top: 0px;

    &__textcontainer {
      padding-block-start: 2rem;
      padding-inline: 2rem;
      @include mq(medium) {
        padding-block-start: 0;
        padding-inline-start: 0;
        padding-inline-end: 2rem;
      }
    }

    p {
      // margin: 15px 15px 0 32px;
      padding-bottom: 33px;
    }

    h1 {
      // margin: 25px 19px 18px 32px;
      // margin-inline: 2rem 1.25rem;
      margin-block: 1.625rem;
      --font-weight: 400;
      font-size: var(--fs-750);
    }

    img {
      margin-left: 2em;
      padding-top: 2em;
      max-width: calc(100% - 2em);
      height: auto;
    }

    @include mq(medium) {
      // padding: 1.375rem;
      padding-inline: 4.375rem;
      padding-block: 3.75rem;
      display: flex;
      flex-direction: row-reverse;
      background: linear-gradient(0deg, var(--clr-primary-5) 30%, var(--clr-secondary-5) 30%);

      img {
        // padding: 2em;
        padding-top: 0;
      }
    }
  }
}
