@use '../abstracts/mixins' as *;

.columns {
  --grid-gap: 1rem;

  padding-inline: 0;

  & > * + * {
    margin: var(--grid-gap) 0 0;
  }

  & .streamfield,
  & .container,
  & .image {
    --spacer: 1rem;
  }

  .streamfield {
    margin-left: 0px;
    margin-right: 0px;
  }

  &__grid {
    @include mq(medium) {
      display: grid;
      gap: var(--grid-gap);
    }

    .streamfield.image > img {
      margin-bottom: 5px;
    }

    &[data-type='column_2_1'] {
      grid-template-columns: minmax(0, 2fr) minmax(0, 1fr);
    }

    &[data-type='column_1_1'] {
      grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
    }
  }
}
