.textarea {
  textarea {
    width: 100%;
    border-radius: var(--border-radius);
    border-color: var(--clr-primary-5, #163f92);
    resize: vertical;
    min-height: 200px;
    padding: 1rem;
    font-size: var(--fs-450);
    color: var(--clr-primary-5, #163f92);

    :focus {
      outline-color: var(--clr-focus-1, black);
    }
  }
}
