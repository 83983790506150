@use '../abstracts' as *;

@each $color, $shade in $colors {
  @each $prop, $value in $shade {
    .text-#{$color}-#{$prop} {
      color: var(--clr-#{$color}-#{$prop}) !important;
    }

    .bg-#{$color}-#{$prop} {
      background-color: var(--clr-#{$color}-#{$prop}) !important;
    }
  }
}

.primary {
  --clr: var(--clr-primary-1);
}

.secondary {
  --clr: var(--clr-secondary-1);
}
