@use '../abstracts/mixins' as *;
@use '../utilities/container';

.container-field {
  padding-block: 5rem;
  background: var(--bg-color, transparent);

  border-radius: var(--border-radius);

  & > .container {
    margin: 0 auto;
  }

  & > .streamfield {
    border: 0;
    border-top-left-radius: var(--border-radius);
    border-top-right-radius: var(--border-radius);
    background: transparent;
  }

  &--full-width {
    width: auto;
  }

  &--light-grey {
    --bg-color: var(--clr-neutral-4, silver);
  }

  &--light-blue {
    --bg-color: var(--clr-blue-4, blue);
  }

  &--gradient-light-blue-dark-blue {
    --bg-color: linear-gradient(to bottom, vvar(--clr-blue-2, lightblue), var(--clr-blue-8, blue));
  }

  &--gradient-light-blue-white {
    --bg-color: linear-gradient(
      to bottom,
      var(--clr-blue-2, lightblue),
      var(--clr-neutral-1, white)
    );
  }
}

@include mq(medium) {
  .container-field {
  }
}
