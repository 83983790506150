@use 'sass:list';
@use '../abstracts' as *;

:root {
  --section-spacing: 3.5rem;
  --spacer-lg: 5rem;
  --spacer-md: 2rem;
  --spacer-sm: 1rem;

  @include mq(medium) {
    --section-spacing: 7rem;
    --spacer-lg: 5rem;
  }
  @each $color, $shade in $colors {
    @each $prop, $value in $shade {
      --clr-#{$color}-#{$prop}: #{$value};
    }
  }

  @each $size, $property in $type-scale {
    --fs-#{$size}: #{fluid(list.nth($property, 1), list.nth($property, 2))};
  }
}
