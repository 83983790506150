@use '../abstracts/mixins' as *;

.quote {
  font-weight: 600;
  // width: min(100%, 1200px);
  position: relative;
  margin-block-start: 150px;
  max-width: 50rem;

  &.primary {
    --clr-bg: var(--clr-primary-1);
    --clr-txt: var(--clr-primary-5);
  }

  &.secondary {
    --clr-bg: var(--clr-secondary-1);
    --clr-txt: var(--clr-primary-5);
  }

  &.grey {
    --clr-bg: var(--clr-neutral-2);
    --clr-txt: var(--clr-primary-5);
  }

  &__image {
    border-radius: 100%;
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    position: absolute;
    right: 50px;
    top: -70px;
    z-index: 1;
  }

  figure {
    display: flex;
    flex-direction: column;
    margin-inline: auto;
    background-color: var(--clr-bg);
    padding: 20px;
    padding-block-start: 120px;
  }

  blockquote {
    // use standard quotes
    //quotes: '„' '”';

    // styled quotes
    quotes: '' ''; //empty quotes, use :after quotes
    position: relative;
    // text-indent: -85px;
    font-size: var(--fs-350);
    margin-block-start: 2rem;
  }

  blockquote:before {
    content: '”';
    font-family: Georgia, serif;
    line-height: 0;
    font-size: 8rem;
    // position: relative;
    // left: 85px;
    left: -3px;
    position: absolute;
    top: -13px;
  }

  figcaption {
    margin-block: 1.25rem;
    font-size: var(--fs-350);
    --font-weight: 600;
    color: var(--clr-txt);
  }
}
