.slider {
  .splide {
    .splide__arrow:focus-visible {
      outline: 2px solid var(--clr-focus-1);
      outline-offset: 3px;
    }

    &__slide {
      background: var(--clr-neutral-2);
      justify-content: center;
      align-items: center;
      display: flex;
    }
  }

  &__image {
    max-width: 100%;
    height: auto;
    object-fit: contain;
    margin-inline: auto;
  }
}
