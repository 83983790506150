.richtext {
  a {
    word-break: break-word;
  }
  ul {
    display: flow-root;
  }
  .richtext-image {
    height: auto;
    &.left {
      float: left;
      margin-inline-end: 1rem;
      clear: left;
    }
    &.right {
      float: right;
      margin-inline-start: 1rem;
      clear: right;
    }
    &.full-width {
      width: min(100% - 2rem, 800px);
    }
  }
}
