.contact {
  &_wrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 1em;
    margin-block-end: 20px;
  }

  &__positionsgruppe,
  &__name {
    --font-weight: 700;
    font-size: 1.3rem;
  }
  &__position {
    padding-block-end: 32px;
  }
  &__textwrapper {
    flex: 1 1;
  }
  &__phone,
  &__mobile,
  &__mail {
    display: flex;
    align-items: center;
    padding-block-end: 10px;
    span {
      padding-inline-start: 10px;
    }
  }
  &__icon {
    max-height: 30px;
    &.mobile {
      position: relative;
      left: 6px;
    }
  }
}
