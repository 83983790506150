@use './label';

.fieldset {
  border: none;
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 1em;
  align-items: stretch;

  &__legend {
    @extend .label !optional;
    margin: 0 0 1em 0;
    text-align: center;
    font-size: var(--fs-700);
  }

  &--as-label &__legend {
    color: var(--clr-primary-5, #163f92);
    font-size: clamp(1.25rem, 4vw, 1.5rem);
    font-weight: 600;
    display: inline-block;
    padding: 0 1rem;
    margin-block-end: 0.5rem;
    text-align: left;
  }
}
