@use '../abstracts/mixins' as *;

.nav {
  position: absolute;
  top: -100%;
  height: 0;
  width: 100%;
  z-index: 1;
  overflow: hidden;
  opacity: 0;
  transition: opacity 0.2s ease;

  &--open nav {
    --nav-open-top: var(--header-height);
    height: 100vh;
    opacity: 0.95;
    border-top: 2px solid var(--clr-secondary-5);
    background: var(--clr-primary-5);
    position: fixed;
    right: 0;
    height: calc(100% - var(--nav-open-top));
    top: var(--nav-open-top);
    bottom: 0;
    opacity: 1;
    overflow-y: scroll;
    a {
      color: var(--clr-neutral-1);
    }

    div.mobilesearch {
      max-width: 1024px;
      margin-inline: auto;
      position: relative;
      top: 2rem;
      padding: 0 40px;
      form {
        border: 1px solid #fff;
        border-radius: 5px;
      }
      input {
        width: calc(100% - 3rem);
        height: 50px;
        border: none;
        -webkit-padding-start: 2rem;
        padding-inline-start: 2rem;
      }
      button {
        height: 50px;
        width: 3rem;
        color: var(--clr-neutral-1);
        background-color: var(--clr-primary-5);
        border: none;
        border-radius: 0 5px 5px 0;
        cursor: pointer;
        svg {
          vertical-align: middle;
        }
      }
    }

    @include mq(medium, max) {
      top: 0;
      border-top: 2px solid var(--clr-primary-5);
      height: 100%;
      //background: #fff;
      div.mobilesearch {
        top: calc(var(--nav-open-top) + 2rem);
        padding: 0 1rem;
      }
      ul.nav__list {
        position: relative;
        top: calc(var(--nav-open-top) + 2.5rem);
      }
    }
  }

  &__list {
    @include mq(medium) {
      max-width: 1024px;
      margin-inline: auto;
      column-count: 2;
      margin-block-start: 5rem;
    }

    @include mq(medium, max) {
      padding-inline-start: 0;
      margin-block-start: 0;
    }

    @include mq(medium, max) {
      ul,
      input,
      .closer,
      input:checked ~ .opener {
        display: none;
      }
      input:checked ~ ul,
      input:checked ~ .closer {
        display: inline-block;
        cursor: pointer;
        width: 100%;
      }
      label {
        --font-weight: 500;
        font-size: 24px;
        display: inline-block;
        cursor: pointer;
        width: 100%;
      }
    }

    @include mq(medium) {
      label,
      input {
        display: none;
      }
    }
  }

  &__list-item {
    position: relative;
    padding-inline: 1rem;
    margin-block-end: 1rem;

    svg {
      position: absolute;
      // top: 10px;
      // right: 20px;
      top: -3px;
      right: 20px;
      height: 40px;
      width: 40px;
    }

    a {
      --font-weight: 500;
      text-decoration: none;
      display: inline-block;
    }
    .nav__sub-menu-item {
      margin-top: 1.25rem;
    }

    .nav__sub-menu a {
      font-size: 1.25rem;

      --font-weight: 400;
    }
    .nav__sub-menu {
      padding-inline-start: 32px;
      @include mq(medium) {
        padding-inline-start: 0;
      }
    }

    @include mq(medium, max) {
      a.nav__link__parent {
        display: none;
      }
    }
  }

  &__link {
    font-size: 1.5rem;

    @include mq(medium, max) {
      &:before {
        content: '';
        display: inline-block;
        transition: all ease 0.3s;
      }

      &:hover,
      &.active,
      &:focus-visible {
        &:before {
          content: '';
          display: inline-block;
          background: var(--clr-primary);
          height: 30px;
          width: 5px;
          position: absolute;
          left: -11px;
          top: 2px;
        }

        &:after {
          display: none;
        }
      }
    }
  }

  &__footer_links {
    border-top: 1px solid var(--clr-neutral-1);
    margin-inline: 1rem;
    margin-block-start: 2rem;
    position: relative;
    top: calc(var(--nav-open-top) + 2rem);
    a {
      text-decoration: none;
      font-variation-settings: 'wght' 700;
      font-size: var(--fs-500);
      display: inline-block;
      margin-block-start: 1rem;
    }
  }
}

@include mq(medium) {
  .nav__list-item:last-of-type {
    .nav__sub-menu {
      right: 0;
      left: inherit;
    }
  }
}

.menutoggle {
  display: inline-flex;
  align-items: center;
}

@include mq(medium, max) {
  .mobileOnly.smartphone {
    margin-block-start: 20px;
    width: 1.8rem;
  }

  .mobileOnly.insta {
    margin-block-start: 15px;
    margin-inline-start: 15px;
    svg {
      width: 3.25rem;
    }
  }

  .desktopOnly {
    display: none;
  }
}

@include mq(small) {
  .mobileOnly {
    display: none;
  }
  .desktopOnly {
    display: flex;
    &.call {
      font-weight: 600;
      font-size: var(--fs-500);
      padding-inline-end: 20px;
    }
    &.contact,
    &.insta {
      margin-block-start: 0px;
      width: initial;
      svg {
        width: 3rem;
      }
    }
    &.insta {
      svg {
        width: 2.75rem;
      }
    }
  }
}

/* Works on Firefox */
nav {
  scrollbar-width: thin;
  scrollbar-color: var(--clr-primary-5) var(--clr-primary-5);
}

/* Works on Chrome, Edge, and Safari */
nav::-webkit-scrollbar {
  width: 1px;
}
nav::-webkit-scrollbar-track {
  background: var(--clr-primary-5);
}
