.message {
  border-radius: var(--border-radius);
  padding-inline: 2.5rem;
  padding-block: 6rem;
  background: linear-gradient(145deg, var(--clr-neutral-2), var(--clr-neutral-5));

  &--error {
    background: var(--clr-red-2, Tomato);
  }

  &--success {
    background: var(--clr-grass-2, LightGreen);
  }

  &--warning {
    background: var(--clr-yellow-2, Khaki);
  }

  &--information {
    background: var(--clr-blue-5, RoyalBlue);
    color: var(--clr-neutral-1, white);
  }

  &__title {
    font-size: var(--fs-500);
    color: var(--clr-white);
    line-height: 1;
  }

  a {
    color: var(--color);
  }
}
