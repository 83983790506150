input {
  &:focus-visible {
    outline-offset: 3px;
    outline-color: var(--clr-accent-1);
  }
}
.input {
  display: flex;
  flex-direction: column;

  &__label {
    color: var(--clr-primary-5, #163f92);
    font-size: 1.125rem;
    user-select: none;
  }

  &__element {
    border: none;
    border-radius: 15px;
    font-size: var(--fs-450);
    padding: 1rem;
    border-radius: var(--border-radius);
    border: 2px solid var(--clr-primary-5, #163f92);
    color: var(--clr-primary-5, #163f92);

    &:focus-visible {
      outline-offset: 3px;
      outline-color: var(--clr-accent);
    }

    &::-webkit-calendar-picker-indicator {
      color: var(--clr-primary-5, #163f92);
    }
  }
}
