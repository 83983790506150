@use '../../utilities/container';
@use '../../abstracts/mixins' as *;

.form {
  @extend .container !optional;

  display: flex;
  flex-direction: column;
  gap: 1em;
  align-items: stretch;

  &__submit {
    width: max-content;
    margin-inline: auto;
    @include mq(small, max) {
      width: 100%;
      border-radius: 1rem !important;
    }
  }
}
