@use '../abstracts/mixins' as *;

.cards {
  --clr-bg: var(--clr-neutral-2);
  --clr-txt: var(--clr-primary-5);
  --clr-shadow: transparent;
  --border-radius: 20px;

  &__header {
    margin-block-end: var(--spacer-md);
  }

  &__footer {
    --spacer: var(--spacer-sm);
  }

  &__wrapper {
    @include mq(small) {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
      grid-gap: 1rem;
      align-items: stretch;
    }
  }

  &__length1 {
    text-align: center;
    .card__icon {
      margin-inline: auto;
    }
  }

  .card {
    padding: 1rem;
    background-color: var(--clr-bg);
    display: flex;
    flex-direction: column;
    margin-block-end: 1.5rem;

    @include mq(big) {
      margin-block-end: 5rem;
      width: 100%;
      max-width: 400px;
    }

    &__iconwrapper {
      text-align: center;
    }

    &__icon {
      margin-block-start: 1.25rem;
      margin-block-end: 1.5rem;
    }

    &__title {
      margin-block-end: 1.5rem;
      color: var(--clr-txt);
    }

    &__text {
      margin-block-end: 1.5rem;
    }

    &__link {
      margin-block-start: auto;
      text-decoration: none;
      font-weight: 600;

      &::before {
        content: '\2794';
        position: relative;
        top: -2px;
        font-size: 1.5625rem;
        vertical-align: middle;
        margin-inline-end: 1rem;
      }
    }
  }

  .roundImg .card {
    background-color: transparent;
    text-align: left;
    font-size: var(--fs-450, 1.5rem);

    &__icon {
      background: var(--clr-neutral-1, white);
      display: flex;
      width: 240px;
      border-radius: 120px;
      margin: 0 auto;
      height: 240px;
      justify-content: center;
      align-items: center;
    }

    &__title {
      margin-block-start: 3.125rem;
    }
  }
}
