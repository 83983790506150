@use '../abstracts/mixins' as *;
.links {
  @include mq(medium, max) {
    width: 100%;
  }
  &.primary,
  &.secondary {
    --clr-txt: var(--clr-neutral-1);
    --gap: 0;
  }

  &.grey {
    --clr-bg: var(--clr-neutral-2);
    --clr-txt: var(--clr-primary-5);
    --gap: 0.19rem;
  }

  &.primary {
    .links__tile:nth-child(9n + 9),
    .links__tile:nth-child(1) {
      --clr-bg: var(--clr-primary-5);
    }
    .links__tile:nth-child(8n + 2),
    .links__tile:nth-child(8n + 8) {
      --clr-bg: var(--clr-primary-4);
    }
    .links__tile:nth-child(8n + 3),
    .links__tile:nth-child(8n + 7) {
      --clr-bg: var(--clr-primary-3);
    }
    .links__tile:nth-child(8n + 4),
    .links__tile:nth-child(8n + 6) {
      --clr-bg: var(--clr-primary-2);
    }
    .links__tile:nth-child(8n + 5),
    .links__tile:nth-child(8n + 5) {
      --clr-bg: var(--clr-primary-1);
    }
  }
  &.secondary {
    .links__tile:nth-child(9n + 9),
    .links__tile:nth-child(1) {
      --clr-bg: var(--clr-secondary-5);
    }
    .links__tile:nth-child(8n + 2),
    .links__tile:nth-child(8n + 8) {
      --clr-bg: var(--clr-secondary-4);
    }
    .links__tile:nth-child(8n + 3),
    .links__tile:nth-child(8n + 7) {
      --clr-bg: var(--clr-secondary-3);
    }
    .links__tile:nth-child(8n + 4),
    .links__tile:nth-child(8n + 6) {
      --clr-bg: var(--clr-secondary-2);
    }
    .links__tile:nth-child(8n + 5),
    .links__tile:nth-child(8n + 5) {
      --clr-bg: var(--clr-secondary-1);
    }
  }
  &__title {
    margin-block-end: 1.5em;
    @include mq(medium, max) {
      margin-inline: 32px;
      margin-block-end: 1em;
    }
  }

  display: grid;
  grid-template-columns: repeat(auto-fit, 1fr);
  grid-gap: var(--gap);
  padding: 0;
  // width: min(100%, 1200px);

  &__tile {
    padding: 1.438em 2em;
    text-decoration: none;
    transition: box-shadow ease 0.1s;
    background-color: var(--clr-bg);
    display: flex;
    align-items: center;
    position: relative;
    &:hover {
      text-decoration: none;
      box-shadow: var(--box-shadow);
    }
    .title {
      font-size: var(--fs-500);
      font-weight: 600;
      color: var(--clr-txt);
      max-width: calc(100% - 2em);
    }
  }

  &__icon {
    color: var(--clr-txt);
    width: 1.75rem;
    height: 1.75rem;
    transition: transform 150ms linear;
    flex-shrink: 0;
    margin-inline-start: 1rem;
    position: absolute;
    right: 23px;

    path {
      fill: var(--clr-txt);
    }

    [data-state='open'] & {
      transform: rotate(180deg);
    }
  }

  .icon,
  .icon svg {
    width: 3.063rem;
    height: auto;
    margin-right: 2em;
  }
}
