.checkbox {
  display: flex;
  // align-items: center;

  &--baseline {
    align-items: flex-start;
  }

  button {
    margin-block-start: 5px;
  }

  &__root {
    all: unset;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 1;
    background-color: white;
    width: 24px;
    height: 24px;
    border-radius: 4px;
    border: 2px solid var(--clr-primary-5, #163f92);
    cursor: pointer;
    flex-shrink: 0;

    &:focus-visible {
      box-shadow: 0 0 0 6px var(--clr-primary-5, #163f92);
    }
  }

  &__indicator {
    color: var(--clr-primary-5, #163f92);
    height: 24px;
  }

  &__label {
    color: var(--clr-primary-5, #163f92);
    padding: 0 0 0 0.5em;
    font-size: clamp(1.125rem, 4vw, 1.5rem);
    line-height: 1.4;
    cursor: pointer;
    user-select: none;
    font-weight: 300;

    &--small {
      font-size: clamp(1rem, 4vw, 1.5rem);
    }

    a {
      color: inherit;
      font-weight: 600;
    }
  }
}
