.button:not(:empty) {
  --border-radius: 5rem;
  --clr-bg: var(--clr-primary-5, #163f92);
  --border-color: var(--clr-primary-5, #163f92);
  --clr-txt: var(--clr-neutral-2, white);
  --font-weight: 600;

  color: var(--clr-txt);
  background-color: var(--clr-bg);
  border: 2px solid var(--border-color);
  border-radius: var(--border-radius, 15px);
  padding: 0.5em 2em;
  cursor: pointer;
  text-decoration: none;
  transition: box-shadow ease 0.2s, color ease 0.2s, background-color ease 0.3s;
  letter-spacing: 0.05rem;

  &:focus-visible {
    outline: 2px solid var(--clr-focus-1);
    outline-offset: 3px;
  }

  &:hover {
    box-shadow: inset 0px 0 6px 2px rgba(0, 0, 0, 0.1);
    color: var(--clr-neutral-1);
    background-color: var(--clr-primary-2);
  }
}
.button--primary:not(:empty) {
  &:hover {
    color: var(--clr-neutral-1);
    background-color: var(--clr-primary-2);
  }
}
