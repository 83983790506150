* {
  // font-variation-settings: 'wght' var(--font-weight, 375), 'wdth' var(--font-width, 80);
  font-variation-settings: 'wght' var(--font-weight, 400);
}

body {
  font-size: var(--fs-300);
  color: var(--clr-primary-5, darkblue);
}

h1,
h2,
h3,
h4 {
  --font-weight: 600;
  font-weight: normal;
  font-stretch: 100%;
  line-height: 1.1;
  hyphens: auto;
  margin-block-end: 0.5em;
}

h1 {
  --font-weight: 600;
}

h2,
h3,
h4 {
  color: var(--clr-primary-5, darkblue);
}

// This is setting the font-sizes
// based on the ones in the abstract
// folder, go there to make updates

h1,
.h1 {
  font-size: var(--fs-800);
}

h2,
.h2 {
  --flow-spacer: 1.5em;
  font-size: var(--fs-600);
  //margin-block-end: var(--spacer-md);
}

h3,
.h3 {
  --flow-spacer: 1.5em;
  font-size: var(--fs-500);
}

h4,
.h4 {
  font-size: var(--fs-400);
}

p {
  --font-weight: 350;
}

small,
.text-small {
  font-size: var(--fs-300);
}

strong {
  --font-weight: 700;
}

a {
  color: var(--clr-primary-5, darkblue);
  transition: color ease 0.2s;

  &:hover,
  &:focus {
    color: var(--clr-primary-5, darkblue);
  }
}

// p:not([class]),
// ul:not([class]),
// li:not([class]) {
//   max-width: 60ch;
// }
