@use '../abstracts/mixins' as *;

.sitefooter {
  background-color: var(--clr-neutral-6);
  color: var(--clr-neutral-1);
  margin-bottom: 0px;
  width: min(100%, 1400px);
  margin: auto;

  &__container {
    margin-block: 30px;
    display: grid;
    grid-column-gap: 1em;
    @include mq(small) {
      grid-template-columns: 1fr 1fr;
    }
  }

  .sitefooter__menu {
    .vinegar_change {
      background: none !important;
      border: none;
      padding: 0 !important;
      color: #fff;
      cursor: pointer;
    }
    .sitefooter__menu-list {
      padding-inline-start: 0;

      .sitefooter__menu-list-item {
        list-style: none;
        padding-block: 5px;

        .sitefooter__menuitem {
          text-decoration: none;
          --font-weight: 600;
          color: var(--clr-neutral-1, white);
        }
      }
      :hover {
        color: var(--clr-neutral-1);
      }
    }
  }
}
