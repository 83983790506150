.accordions {
  width: min(100%, 800px);
  --clr-txt: var(--clr-neutral-1);
  --clr-content-txt: var(--clr-primary-5);

  &__primary,
  &__grey {
    .accordion:nth-child(9n + 9),
    .accordion:nth-child(1) {
      --clr-bg: var(--clr-primary-5);
    }
    .accordion:nth-child(8n + 2),
    .accordion:nth-child(8n + 8) {
      --clr-bg: var(--clr-primary-4);
    }
    .accordion:nth-child(8n + 3),
    .accordion:nth-child(8n + 7) {
      --clr-bg: var(--clr-primary-3);
    }
    .accordion:nth-child(8n + 4),
    .accordion:nth-child(8n + 6) {
      --clr-bg: var(--clr-primary-2);
    }
    .accordion:nth-child(8n + 5),
    .accordion:nth-child(8n + 5) {
      --clr-bg: var(--clr-primary-1);
    }
  }
  &__secondary {
    .accordion:nth-child(9n + 9),
    .accordion:nth-child(1) {
      --clr-bg: var(--clr-secondary-5);
    }
    .accordion:nth-child(8n + 2),
    .accordion:nth-child(8n + 8) {
      --clr-bg: var(--clr-secondary-4);
    }
    .accordion:nth-child(8n + 3),
    .accordion:nth-child(8n + 7) {
      --clr-bg: var(--clr-secondary-3);
    }
    .accordion:nth-child(8n + 4),
    .accordion:nth-child(8n + 6) {
      --clr-bg: var(--clr-secondary-2);
    }
    .accordion:nth-child(8n + 5),
    .accordion:nth-child(8n + 5) {
      --clr-bg: var(--clr-secondary-1);
    }
  }
}

.accordion {
  --padding: 1.5rem;
  --border-radius: 1rem;
  overflow: hidden;
  color: var(--clr-txt);
  background: var(--clr-bg);

  margin-block-start: 0;

  &__header {
    all: unset;
    display: flex;
  }

  &__trigger {
    all: unset;
    background-color: transparent;
    padding: var(--padding);
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: var(--fs-500);
    font-weight: 600;
    line-height: 1.3;
    cursor: pointer;

    &:focus-visible {
      outline: 2px solid var(--clr-focus-1);
      outline-offset: -6px;
      border-radius: var(--border-radius);
    }
  }

  &__icon {
    color: var(--clr-primary-5, #163f92);
    width: 1.75rem;
    height: 1.75rem;
    transition: transform 150ms linear;
    flex-shrink: 0;
    margin-inline-start: 1rem;

    path {
      fill: var(--clr-txt);
    }

    [data-state='open'] & {
      transform: rotate(180deg);
    }
  }

  &__content {
    overflow: hidden;
    font-size: var(--fs-400);
    padding: var(--padding);
    background: var(--clr-txt);
    color: var(--clr-content-txt);
    .richtext.streamfield {
      margin-block-end: 5px;
    }

    &[data-state='open'] {
      animation: slideDown 100ms linear forwards;
    }

    &[data-state='closed'] {
      animation: slideUp 100ms linear forwards;
    }
  }
}

@keyframes slideUp {
  from {
    height: var(--radix-accordion-content-height);
  }
  to {
    height: 0;
  }
}

@keyframes slideDown {
  from {
    height: 0;
  }
  to {
    height: var(--radix-accordion-content-height);
  }
}
