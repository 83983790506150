@use '../abstracts/mixins' as *;

.streamfield.search {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1rem 3.5rem;

  .searchform {
    display: flex;
    // width: min(100%, 1200px);
    width: 100%;
    text-align: center;

    &__input {
      box-sizing: border-box;
      height: 50px;
      width: 90%;
      border: 1px solid var(--clr-primary-5);
      border-radius: 5px 0 0 5px;
      padding-inline-start: 2rem;
    }

    &__button {
      height: 50px;
      width: 78px;
      color: var(--clr-neutral-1);
      border-radius: 0 5px 5px 0;
      background-color: var(--clr-primary-5);
      border: none;
      cursor: pointer;
      svg {
        vertical-align: middle;
      }
    }
  }

  .search__buttoncontainer {
    display: flex;
    gap: 0.4rem;
    flex-wrap: wrap;

    @include mq(medium, min) {
      gap: 1rem;
    }

    .button {
      --font-weight: 400;
      font-size: var(--fs-250);
      background-color: var(--clr-secondary-5);
      border-radius: 5px;
      border: 2px solid var(--clr-secondary-5);
      padding-inline: 0.8rem;
    }
  }
}

.container.search {
  .searchform {
    // width: min(100%, 1200px);

    text-align: center;
    margin-block-end: 20px;
    display: flex;

    &__input {
      box-sizing: border-box;
      height: 50px;
      width: 90%;
      border: 1px solid var(--clr-primary-5);
      border-radius: 5px 0 0 5px;
      padding-inline-start: 2rem;
    }

    &__button {
      height: 50px;
      width: 10%;
      color: var(--clr-neutral-1);
      border-radius: 0 5px 5px 0;
      background-color: var(--clr-primary-5);
      border: none;
      cursor: pointer;
      svg {
        vertical-align: middle;
      }
    }
  }

  .search-results {
    &__item {
      font-size: var(--fs-400);
    }

    &__icon {
      margin-right: 10px;
    }

    &__title {
      font-size: var(--fs-400);
    }
  }
}
